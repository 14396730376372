import {
    defineComponent,
    forwardRef,
    Frame,
    html,
    Label,
    namespaced,
    Toast,
    withNamespace,
    type Component,
    type Props,
    type RefObject,
    type ToastBarControls,
} from '@monkey-tilt/ui';
import type { BlackjackClient } from '../../../game/blackjack';
import { scopedState } from '../../../util/state';
import type { AppContext } from '../../context';
import { Hand, type HandProps } from './hand';

export interface TableProps extends Props<HTMLDivElement> {
    /**
     * The Blackjack game client.
     */
    readonly client: BlackjackClient;

    readonly context: AppContext;

    readonly toastBarControlsRef: RefObject<ToastBarControls>;
}

export const Table = defineComponent(
    'Table',
    () =>
        ({
            client,
            context,
            toastBarControlsRef,
            ...props
        }: TableProps): Component<HTMLDivElement> => {
            const { previousMemo, memo, effect, ref } = scopedState<HTMLDivElement>();

            const div = html('div');

            const playerTable: RefObject<HTMLDivElement> = { current: null };
            const deck: RefObject<HTMLDivElement> = { current: null };

            const dealerHand = memo(() => client.state().dealer_hand);
            const playerHands = previousMemo(() => client.state().player_hands);
            const activeHand = memo(() => {
                const { hand_index, hand_owner, player_hands } = client.state();
                return {
                    shouldHighlight: player_hands.length > 1,
                    index: hand_index,
                    owner: hand_owner,
                };
            });

            function animateHand(
                element: RefObject<HTMLDivElement>,
                { isDealer = false }: { isDealer?: boolean } = {},
            ): Partial<HandProps> {
                const before = { x: 0, y: 0 };
                let firstRender = true;
                return {
                    onBeforeCardsAdded: () => {
                        if (element.current) {
                            const { x, y } = element.current.getBoundingClientRect();
                            before.x = x;
                            before.y = y;
                            if (firstRender) {
                                element.current.style.visibility = 'hidden';
                            }
                        }
                    },
                    onCardsAdded: ({ detail: cards }) => {
                        if (deck.current) {
                            const deckRect = deck.current.getBoundingClientRect();
                            for (let i = 0; i < cards.length; i++) {
                                const card = cards[i]!;
                                const rect = card.getBoundingClientRect();

                                const fromX = deckRect.x - rect.x;
                                const fromY = deckRect.y - rect.y - deckRect.height;

                                card.animate(
                                    [
                                        { transform: 'translate(0, 0)' },
                                        { transform: `translate(${fromX}px, ${fromY}px)` },
                                    ],
                                    {
                                        duration: 1,
                                        fill: 'forwards',
                                    },
                                );

                                card.animate(
                                    [
                                        {
                                            transform: `translate(${fromX}px, ${fromY}px)`,
                                        },
                                        { transform: 'translate(0, 0)' },
                                    ],
                                    {
                                        delay: i * 100,
                                        duration: 300,
                                        easing: 'ease-in-out',
                                        fill: 'forwards',
                                    },
                                );
                            }

                            if (firstRender && element.current) {
                                setTimeout(() => {
                                    element.current!.style.visibility = 'visible';
                                }, 100);
                            }
                        }

                        if (firstRender || !element.current) {
                            firstRender = false;
                            return;
                        }

                        const { x, y } = element.current.getBoundingClientRect();

                        element.current.animate(
                            [
                                {
                                    transform: `translate(${before.x - x}px, ${before.y - y}px)`,
                                },
                                { transform: 'translate(0, 0)' },
                            ],
                            {
                                duration: 150,
                                easing: 'ease-out',
                                fill: 'forwards',
                            },
                        );
                    },
                    onBeforeCardsRemoved: (event) => {
                        event.preventDefault();
                        for (let i = 0; i < event.detail.length; i++) {
                            const animation = event.detail[i]!.animate(
                                [
                                    { opacity: 1, transform: 'translate(0, 0)' },
                                    { opacity: 0, transform: 'translate(-50%, 30%)' },
                                ],
                                {
                                    delay: i * 50,
                                    duration: 150,
                                    easing: 'ease-out',
                                    fill: 'forwards',
                                },
                            );
                            if (i === event.detail.length - 1) {
                                animation.onfinish = () => {
                                    event.detail.forEach((card) => card.remove());
                                    if (
                                        !element.current!.querySelector(
                                            `.${withNamespace('m-card')}`,
                                        )
                                    ) {
                                        firstRender = true;
                                        if (!isDealer) {
                                            element.current!.remove();
                                        }
                                    }
                                };
                            }
                        }
                    },
                };
            }

            effect(() => {
                const { current, previous } = playerHands();
                if (!playerTable.current || current == previous) {
                    return;
                }

                const removed = previous.filter((hand) => !current.some((h) => h.id == hand.id));

                for (const { id } of removed) {
                    playerTable.current.querySelector(`[data-hand-id="${id}"]`)?.remove();
                }

                for (let index = 0; index < current.length; index++) {
                    const { id, splitFrom } = current[index]!;
                    if (playerTable.current.querySelector(`[data-hand-id="${id}"]`) != null) {
                        continue;
                    }

                    const element: RefObject<HTMLDivElement> = { current: null };

                    element.current = Hand({
                        context,
                        hand: memo(() => playerHands().current.find((hand) => hand.id == id)!),
                        isHighlighted: memo(() => {
                            const { shouldHighlight, owner, index } = activeHand();
                            return (
                                shouldHighlight &&
                                owner == 'Player' &&
                                index == playerHands().current.findIndex((hand) => hand.id == id)
                            );
                        }),
                        ...animateHand(element),
                    }).render();

                    const splitFromElement = splitFrom
                        ? playerTable.current.querySelector(`[data-hand-id="${splitFrom}"]`)
                        : null;

                    if (splitFromElement) {
                        splitFromElement.insertAdjacentElement('afterend', element.current);
                    } else {
                        playerTable.current.appendChild(element.current);
                    }
                }
            });

            const dealerHandElement: RefObject<HTMLDivElement> = { current: null };

            return Frame(
                {
                    ...props,
                    ref,
                    className: { 'm-blackjack__table': true },
                },
                div(
                    { className: namespaced('m-blackjack__dealer') },
                    Hand({
                        context,
                        hand: dealerHand,
                        isDealer: true,
                        ref: dealerHandElement,
                        ...animateHand(dealerHandElement, { isDealer: true }),
                    }),
                ),
                div(
                    { className: namespaced('l-stack', 'u-gap--xs', 'm-blackjack__ribbons') },
                    Label({ text: 'Blackjack pays 3 to 2', variant: 'ribbon-l' }),
                    Label({ text: 'Insurance pays 2 to 1', variant: 'ribbon-r' }),
                ),
                div({ className: namespaced('m-blackjack__player'), ref: playerTable }),
                div(
                    {
                        className: namespaced('m-blackjack__deck'),
                        ref: forwardRef(deck, (element: HTMLDivElement) => {
                            const isTableEmpty = memo(
                                () => client.state().dealer_hand.cards.length == 0,
                            );
                            effect(() => {
                                element.classList.toggle(
                                    withNamespace('is-visible'),
                                    !isTableEmpty(),
                                );
                            });
                        }),
                    },
                    div(),
                    div(),
                    div(),
                ),
                Toast.Bar({
                    position: 'absolute',
                    controls: (toastControls) => {
                        toastBarControlsRef.current = toastControls;
                    },
                }),
            );
        },
);
