import type { Transport } from '@monkey-tilt/client';
import { withNamespace } from '@monkey-tilt/ui';
import { BlackjackClient } from './game/blackjack';
import { Blackjack } from './ui';
import { setStateRoot } from './util/state';

export interface BlackjackOptions {
    readonly container: HTMLElement | string;
    readonly transports: Transport[];
}

export function run({ transports, container }: BlackjackOptions): BlackjackClient {
    const client = new BlackjackClient({ transports });

    const element: HTMLElement | null =
        typeof container === 'string' ? document.querySelector(container) : container;

    if (!element) {
        throw new Error('Invalid container provided');
    }

    element.classList.add(
        ...['s-app', 'l-cover', 'l-cover--stretch', 'm-blackjack-container'].map(withNamespace),
    );
    element.style.setProperty(`--${withNamespace('min-block-size')}`, '100%');

    setStateRoot(element);

    Blackjack({ client }).renderTo(element);

    return client;
}
