import {
    batch,
    effect,
    memo,
    signal,
    untracked,
    type EffectOptions,
    type SignalOptions,
    type SignalReader,
    type Store,
} from '@monkey-tilt/state';
import { UnmountObserver, type Ref } from '@monkey-tilt/ui';

let observer: UnmountObserver | null = null;
const pending = new Map<HTMLElement, AbortController>();

export function setStateRoot(root: HTMLElement): void {
    observer = new UnmountObserver(root);

    for (const [element, controller] of pending) {
        if (!root.contains(element)) {
            controller.abort();
            continue;
        }
        observer.observe(element, controller);
    }

    pending.clear();
}

export interface PreviousMemo<T> {
    readonly previous: T;
    readonly current: T;
}

export interface ElementScopedState<T extends HTMLElement> extends Store {
    readonly ref: Ref<T>;
    readonly previousMemo: <T>(value: SignalReader<T>) => SignalReader<PreviousMemo<T>>;
}

export function scopedState<T extends HTMLElement>(): ElementScopedState<T> {
    const controller = new AbortController();
    const abortSignal = controller.signal;

    const withAbortSignal = <T extends EffectOptions>(options: T | undefined): T => {
        if (!options) {
            return { abortSignal } as T;
        }
        if (options.abortSignal) {
            options.abortSignal.addEventListener('abort', () => controller.abort(), {
                once: true,
            });
        }
        return { ...options, abortSignal } as T;
    };

    const scopedEffect = (execute: () => void, options?: EffectOptions): (() => void) =>
        effect(execute, withAbortSignal(options));

    const scopedMemo = <T>(
        compute: () => T,
        options?: SignalOptions & EffectOptions,
    ): SignalReader<T> => memo(compute, withAbortSignal(options));

    return {
        signal,
        untracked,
        batch,
        effect: scopedEffect,
        memo: scopedMemo,
        previousMemo: <T>(value: SignalReader<T>): SignalReader<PreviousMemo<T>> => {
            const current = value();
            const [get, set] = signal({ current, previous: current });

            scopedEffect(() => {
                set({
                    current: value(),
                    previous: untracked(get).current,
                });
            });

            return get;
        },
        ref(element: T): void {
            if (!observer) {
                pending.set(element, controller);
            } else {
                observer.observe(element, controller);
            }
        },
    };
}
