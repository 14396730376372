/**
 * Performs a simple deep merge of the given objects.
 *
 * If a merged value is an array, the values are concatenated.
 *
 * @param target - The target object to merge into (modified in-place).
 * @param objects - The objects to merge.
 * @returns The target object.
 */
export function merge<T>(target: T, ...objects: Partial<T>[]): T {
    const result = (target ?? {}) as Record<string, unknown>;
    for (const obj of objects) {
        for (const key in obj) {
            const value = obj[key];
            if (value === undefined) {
                continue;
            }
            if (
                !(key in result) ||
                result[key] === undefined ||
                typeof value !== 'object' ||
                value === null
            ) {
                result[key] = value;
                continue;
            }
            if (Array.isArray(value)) {
                result[key] = Array.isArray(result[key])
                    ? [...(result[key] as unknown[]), ...value]
                    : value;
            } else {
                merge(result[key], value);
            }
        }
    }
    return result as T;
}
