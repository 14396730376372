import { timer } from '@monkey-tilt/client';
import type { SignalReader } from '@monkey-tilt/state';
import {
    applyClasses,
    defineComponent,
    forwardRef,
    html,
    Label,
    mergeClasses,
    type Component,
    type Props,
    type RefObject,
} from '@monkey-tilt/ui';
import type { Hand as HandData } from '../../../game/state';
import { scopedState } from '../../../util/state';
import type { AppContext } from '../../context';
import { Card } from '../card/card';

export class CardsAddedEvent extends CustomEvent<HTMLDivElement[]> {
    constructor(cards: HTMLDivElement[]) {
        super('cardsadded', { detail: cards, cancelable: true });
    }
}

export class CardsRemovedEvent extends CustomEvent<HTMLDivElement[]> {
    constructor(cards: HTMLDivElement[]) {
        super('cardsremoved', { detail: cards, cancelable: true });
    }
}

export interface HandProps extends Props<HTMLDivElement> {
    readonly context: AppContext;

    /**
     * The signal reader for the given hand.
     */
    readonly hand: SignalReader<HandData>;

    /**
     * The signal reader for whether the hand is highlighted.
     */
    readonly isHighlighted?: SignalReader<boolean>;

    /**
     * Whether the hand is the dealer's hand.
     */
    readonly isDealer?: boolean;

    /**
     * Called with an array of card elements before new cards are added to the hand.
     */
    readonly onBeforeCardsAdded?: (event: CardsAddedEvent) => void;

    /**
     * Called with an array of card elements when new cards are added to the hand.
     */
    readonly onCardsAdded?: (event: CardsAddedEvent) => void;

    /**
     * Called with an array of card elements before cards are removed from the hand.
     */
    readonly onBeforeCardsRemoved?: (event: CardsRemovedEvent) => void;

    /**
     * Called with an array of card elements when cards are removed from the hand.
     */
    readonly onCardsRemoved?: (event: CardsRemovedEvent) => void;
}

const revealAfter = 800;

export const Hand = defineComponent(
    'Hand',
    () =>
        ({
            context,
            hand,
            isHighlighted = () => false,
            isDealer = false,
            onBeforeCardsAdded,
            onCardsAdded,
            onBeforeCardsRemoved,
            onCardsRemoved,
            ...props
        }: HandProps): Component<HTMLDivElement> => {
            const { previousMemo, memo, effect, ref } = scopedState<HTMLDivElement>();

            const facedownCard: RefObject<HTMLDivElement> = { current: null };

            const state = previousMemo(hand);

            const sum = memo(() => {
                const cards = hand()?.cards;

                if (!cards) {
                    return '0';
                }

                let hasAce = false;

                const sum = cards.reduce((sum, card) => {
                    if (card.index == 0) {
                        hasAce = true;
                    }
                    return sum + card.value;
                }, 0);

                if (!hasAce || sum + 10 > 21) {
                    return `${sum}`;
                }

                return `${sum}/${sum + 10}`;
            });

            const status = memo(() => hand()?.status);

            return html('div')(
                {
                    ...props,
                    className: mergeClasses(props.className, { 'm-blackjack__hand': true }),
                    'data-hand-id': hand().id,
                    ref: forwardRef(props.ref, ref, (element: HTMLDivElement) => {
                        let hasRendered = false;

                        effect(() => {
                            const { current, previous } = state();
                            if (!current || (hasRendered && current == previous)) {
                                return;
                            }

                            const addedCards: HTMLDivElement[] = [];
                            const removedCards: HTMLDivElement[] = [];

                            const numRendered = hasRendered ? previous.cards.length : 0;
                            hasRendered = true;

                            if (current.cards.length > numRendered) {
                                for (let i = numRendered; i < current.cards.length; i++) {
                                    const card = current.cards[i]!;
                                    addedCards.push(
                                        Card({
                                            card,
                                            revealAfter: card.splitFrom ? -1 : revealAfter,
                                            onReveal: () => {
                                                void context.playSound('flip');
                                            },
                                        }).render(),
                                    );
                                }

                                if (isDealer) {
                                    if (facedownCard.current) {
                                        element.appendChild(addedCards.pop()!);
                                        facedownCard.current.remove();
                                        facedownCard.current = null;
                                    } else if (current.cards.length == 1) {
                                        addedCards.push(
                                            Card({ ref: facedownCard, revealAfter: 0 }).render(),
                                        );
                                    }
                                }
                            } else if (current.cards.length === numRendered) {
                                const newCards = current.cards.filter(
                                    ({ id }) => previous.cards.find((c) => c.id == id) == null,
                                );
                                if (newCards.length > 0) {
                                    previous.cards
                                        .filter(
                                            ({ id }) =>
                                                current.cards.find((c) => c.id == id) == null,
                                        )
                                        .forEach((card) => {
                                            const cardElement = element.querySelector(
                                                `[data-card-id="${card.id}"]`,
                                            );
                                            if (cardElement) {
                                                cardElement.remove();
                                            }
                                        });

                                    for (const card of newCards) {
                                        addedCards.push(Card({ card, revealAfter }).render());
                                    }
                                }
                            } else {
                                for (const { id } of previous.cards) {
                                    if (current.cards.some((c) => c.id == id)) {
                                        continue;
                                    }
                                    const cardElement = element.querySelector<HTMLDivElement>(
                                        `[data-card-id="${id}"]`,
                                    );
                                    if (cardElement) {
                                        removedCards.push(cardElement);
                                    }
                                }

                                hasRendered = current.cards.length > 0;

                                if (isDealer && !hasRendered && facedownCard.current) {
                                    removedCards.push(facedownCard.current);
                                    facedownCard.current = null;
                                }
                            }

                            if (removedCards.length > 0) {
                                void Promise.resolve().then(() => {
                                    const event = new CardsRemovedEvent(removedCards);
                                    onBeforeCardsRemoved?.(event);

                                    if (!event.defaultPrevented) {
                                        for (const card of removedCards) {
                                            card.remove();
                                        }

                                        if (onCardsRemoved) {
                                            void Promise.resolve().then(() => {
                                                onCardsRemoved(new CardsRemovedEvent(removedCards));
                                            });
                                        }
                                    }
                                });
                            }

                            if (addedCards.length > 0) {
                                void Promise.resolve().then(() => {
                                    const event = new CardsAddedEvent(addedCards);
                                    onBeforeCardsAdded?.(event);

                                    if (!event.defaultPrevented) {
                                        for (const card of addedCards) {
                                            element.appendChild(card);
                                        }

                                        if (onCardsAdded) {
                                            void Promise.resolve().then(() => {
                                                onCardsAdded(new CardsAddedEvent(addedCards));
                                            });
                                        }
                                    }
                                });
                            }
                        });

                        const statusUpdateDelay = timer(revealAfter + 100);

                        effect(() => {
                            const handStatus = status();
                            const highlighted = isHighlighted();
                            statusUpdateDelay(() => {
                                applyClasses(element, [
                                    {
                                        'is-highlighted': highlighted,
                                        'm-blackjack__hand--is-bust': handStatus === 'bust',
                                        'm-blackjack__hand--is-win': handStatus === 'win',
                                        'm-blackjack__hand--is-push': handStatus === 'push',
                                    },
                                ]);
                            });
                        });
                    }),
                },
                Label({
                    text: sum(),
                    variant: 'box',
                    controls({ setText }) {
                        const sumUpdateDelay = timer(revealAfter + 100);
                        effect(() => {
                            const newSum = sum();
                            sumUpdateDelay(() => setText(newSum));
                        });
                    },
                }),
            );
        },
);
