import {
    GameClient,
    GameError,
    isGameUpdate,
    timer,
    uuid,
    type AnyState,
    type GameActionName,
    type ReadyState,
    type RequestData,
    type Transport,
} from '@monkey-tilt/client';
import { effect, memo, signal, type SignalReader, type SignalUpdater } from '@monkey-tilt/state';
import { merge } from '../util/merge';
import { pick } from '../util/pick';
import { writable, type Writable } from '../util/types';
import type { BlackjackState } from './state';

export class BlackjackClient extends GameClient<'Blackjack'> {
    #getState: SignalReader<BlackjackState>;
    #setState: SignalUpdater<BlackjackState>;

    #emptyState = {
        dealer_hand: {
            id: uuid(),
            cards: [],
            status: 'playing',
        },
        player_hands: [
            {
                id: uuid(),
                cards: [],
                bet_amount: '0.00',
                status: 'playing',
            },
        ],
        hand_owner: 'Player',
        hand_index: 0,
        round_closed: false,
    } as const satisfies Partial<BlackjackState>;

    public constructor({ transports }: { transports: Transport[] }) {
        super({
            gameId: 'Blackjack',
            transports,
            actionTimeout: 1000,
            autoRetryAttempts: 3,
        });

        [this.#getState, this.#setState] = signal<BlackjackState>({
            error: null,
            readyState: 'closed',
            round_id: '',
            ...this.#emptyState,
            currency: 'USD',
            bet_type: 'standard',
            bet_amount: '0.00',
            next_actions: [],
        });

        const nextActions = memo(() => {
            const { next_actions, hand_owner } = this.#getState();
            return {
                next_actions,
                hand_owner,
            };
        });

        const dealerActionDelay = timer(300);

        effect(() => {
            const { next_actions, hand_owner } = nextActions();

            if (hand_owner === 'Dealer' && next_actions.includes('Hit')) {
                dealerActionDelay(() => void this.#send('Hit').catch(console.error));
            } else {
                dealerActionDelay.cancel();
            }
        });

        this.onReadyStateChanged((readyState) => {
            const { readyState: prevReadyState, round_id } = this.#getState();
            this.#setState((state) => ({
                ...state,
                readyState,
            }));
        });

        this.onError((event) => {
            if (event.error instanceof GameError) {
                this.#setState((state) => ({
                    ...state,
                    error: event.error as GameError,
                }));
            }
        });
    }

    public get state(): SignalReader<BlackjackState> {
        return this.#getState;
    }

    public bet(request: RequestData<'Bet'>): Promise<void> {
        if (this.allowedActions.has('Bet')) {
            this.#setState((state) =>
                merge(state, {
                    bet_amount: request.bet_amount.toFixed(2),
                    poker_bet_amount: request.poker_bet_amount?.toFixed(2) ?? '0.00',
                    perfect_pairs_amount: request.perfect_pairs_amount?.toFixed(2) ?? '0.00',
                }),
            );
        }

        return this.#send('Bet', request);
    }

    public peek(request: RequestData<'Peek'>): Promise<void> {
        return this.#send('Peek', request);
    }

    public action(action: 'Hit' | 'Stand' | 'Surrender' | 'Double' | 'Split'): Promise<void> {
        return this.#send(action);
    }

    public reset(): void {
        this.allowedActions.clear();
        if (this.isAuthenticated) {
            this.allowedActions.add('Open');
        }

        writable(this.#emptyState.dealer_hand).id = uuid();
        writable(this.#emptyState.player_hands[0]).id = uuid();

        this.#setState((state) => ({
            ...state,
            ...this.#emptyState,
            error: null,
            next_actions: ['Open'] as const,
        }));
    }

    async #send(action: GameActionName, request: RequestData<GameActionName>): Promise<void> {
        this.#setState((state) => ({
            ...state,
            next_actions: [],
        }));
        await this.sendAndAwait(action, request);
    }

    protected override handleUpdate(state: AnyState): void {
        if (state.type === 'Authenticate') {
            this.reset();
            return;
        }
        if (!isGameUpdate(state)) {
            return;
        }

        const newState = structuredClone(this.#getState()) as Writable<BlackjackState>;

        if (state.round_id) {
            newState.round_id = state.round_id;
        }

        newState.next_actions = state.data.next_actions;
        this.allowedActions = new Set(newState.next_actions);

        if (state.action === 'Open') {
            this.#setState({
                ...newState,
                ...this.#emptyState,
                insurance: undefined,
                poker: undefined,
                perfect_pairs: undefined,
            });
            return;
        }

        const playingHandIndex = newState.hand_index;

        merge(
            newState,
            pick(
                state.data,
                'hand_index',
                'hand_owner',
                'poker',
                'perfect_pairs',
                'insurance',
                'round_closed',
                'total_payout',
                'dealer_hand',
            ),
        );

        for (const card of newState.dealer_hand.cards) {
            if (!card.id) {
                writable(card).id = uuid();
            }
        }

        if (state.data.round_closed) {
            const total_payout = Number.parseFloat(state.data.total_payout ?? '0.00');
            const status = total_payout > 0 ? 'win' : 'bust';
            for (const hand of newState.player_hands) {
                if (hand.status === 'playing') {
                    writable(hand).status = status;
                }
            }
        }

        if ('player_hands' in state.data) {
            if (state.action == 'Split') {
                const originalHand = newState.player_hands[playingHandIndex]!;
                writable(originalHand.cards[1]!).splitFrom = originalHand.id;
                newState.player_hands.splice(
                    playingHandIndex,
                    1,
                    {
                        ...originalHand,
                        cards: [
                            originalHand.cards[0]!,
                            {
                                ...state.data.player_hands[0]!.cards[1]!,
                                id: uuid(),
                            },
                        ],
                    },
                    {
                        ...originalHand,
                        id: uuid(),
                        splitFrom: originalHand.id,
                        cards: [
                            originalHand.cards[1]!,
                            {
                                ...state.data.player_hands[1]!.cards[1]!,
                                id: uuid(),
                            },
                        ],
                    },
                );
            } else if (state.data.player_hands.length > 0) {
                let offset = 0;
                let { length } = state.data.player_hands;

                if (length == 1) {
                    offset = playingHandIndex;
                } else if (state.data.player_hands.length !== newState.player_hands.length) {
                    length = Math.min(state.data.player_hands.length, newState.player_hands.length);
                }

                for (let i = 0; i < length; i++) {
                    const hand = state.data.player_hands[i]!;
                    if (hand.cards && Array.isArray(hand.cards)) {
                        for (const card of hand.cards) {
                            writable(card).id = uuid();
                        }
                    }
                    merge(newState.player_hands[offset + i]!, hand);
                }
            }
        }

        this.#setState(newState);
    }
}
