import type { GameActionName, RequestData } from '@monkey-tilt/client';
import {
    Button,
    defineComponent,
    Frame,
    html,
    Label,
    namespaced,
    Tabs,
    withNamespace,
    type Component,
    type IconName,
    type Props,
} from '@monkey-tilt/ui';
import type { BlackjackClient } from '../../../game/blackjack';
import { scopedState } from '../../../util/state';
import type { Writable } from '../../../util/types';
import { BetAmount } from '../bet-amount/bet-amount';
import { Options } from '../options/options';
import type { AppContext } from '../../context';

export interface SidebarProps extends Props<HTMLDivElement> {
    /**
     * The Blackjack game client.
     */
    readonly client: BlackjackClient;

    readonly context: AppContext;
}

export const Sidebar = defineComponent(
    'Sidebar',
    () =>
        ({ client, context, ...props }: SidebarProps): Component<HTMLDivElement> => {
            const { effect, memo, signal, ref } = scopedState<HTMLDivElement>();

            const div = html('div');

            const activeTab = signal('standard');
            const betAmount = signal('0.00');
            const perfectPairAmount = signal('0.00');
            const pokerAmount = signal('0.00');

            effect(() => {
                betAmount.update(client.state().bet_amount);
                perfectPairAmount.update(client.state().perfect_pairs_amount ?? '0.00');
                pokerAmount.update(client.state().poker_bet_amount ?? '0.00');
            });

            const bettingLocked = memo(() => !client.state().next_actions.includes('Bet'));

            const handleInsuranceAvailable =
                ({ show }: { show: boolean }) =>
                (element: HTMLElement) => {
                    effect(() => {
                        element.classList.toggle(
                            withNamespace('u-hide'),
                            client.state().next_actions.includes('Peek') ? !show : show,
                        );
                    });
                };

            const handleActionAvailable =
                (action: GameActionName) => (element: HTMLButtonElement) => {
                    effect(() => {
                        const { next_actions, hand_owner } = client.state();
                        element.disabled = hand_owner == 'Dealer' || !next_actions.includes(action);
                    });
                };

            const actionButton = (
                action: 'Hit' | 'Stand' | 'Surrender' | 'Double' | 'Split',
                icon: IconName,
            ) =>
                Button({
                    label: action,
                    icon,
                    ref: handleActionAvailable(action),
                    onClick: () => void client.action(action).catch(console.error),
                });

            const betButton = Button({
                cta: true,
                label: 'Bet',
                icon: 'plus',
                className: { 'm-blackjack__bet-button': true },
                ref: (element: HTMLButtonElement) => {
                    handleActionAvailable('Bet')(element);
                    handleInsuranceAvailable({ show: false })(element);
                },
                onClick() {
                    const bet_amount = Number.parseFloat(betAmount.read());

                    if (!Number.isFinite(bet_amount) || bet_amount <= 0.001) {
                        context.showToast({
                            content: 'Please enter a valid bet amount.',
                            variant: 'error',
                        });
                        return;
                    }

                    const request: Writable<RequestData<'Bet'>> = {
                        bet_amount,
                        currency: client.state().currency,
                    };

                    if (activeTab.read() === 'sidebet') {
                        const perfect_pairs_amount = Number.parseFloat(perfectPairAmount.read());

                        if (perfect_pairs_amount > 0) {
                            request.perfect_pairs_amount = perfect_pairs_amount;
                        }

                        const poker_bet_amount = Number.parseFloat(pokerAmount.read());

                        if (poker_bet_amount > 0) {
                            request.poker_bet_amount = poker_bet_amount;
                        }
                    }

                    void client.bet(request).catch(console.error);

                    void context.playSound('bet');
                },
            });

            return Frame(
                { ...props, ref, className: { 'm-blackjack__sidebar': true } },

                Frame.Section(
                    { variant: 'subtle', className: { 'm-blackjack__tabs': true } },
                    Tabs({
                        ariaLabel: 'Bet type',
                        value: activeTab.read(),
                        tabs: [
                            { label: 'Standard', value: 'standard' },
                            { label: 'Side Bet', value: 'sidebet' },
                        ],
                        onChange(value) {
                            activeTab.update(value);
                        },
                        controls({ selectTab, setEnabled }) {
                            effect(() => {
                                selectTab(activeTab.read());
                            });
                            effect(() => {
                                setEnabled(!bettingLocked());
                            });
                        },
                    }),
                ),

                Frame.Section(
                    {
                        variant: 'subtle',
                        className: { 'm-blackjack__bet': true },
                        ref: (element: HTMLDivElement) => {
                            effect(() => {
                                element.classList.toggle(withNamespace('u-hide'), bettingLocked());
                            });
                        },
                    },
                    betButton,
                ),

                Frame.Section(
                    {
                        variant: 'subtle',
                        className: { 'u-gap--l': true, 'm-blackjack__controls': true },
                    },

                    BetAmount({
                        label: 'Bet Amount',
                        tooltip: 'The amount of money you want to bet.',
                        value: betAmount,
                        disabled: bettingLocked,
                        presets: [0.1, 1, 10, 100],
                    }),

                    div(
                        {
                            className: { 'l-stack': true },
                            ref(element: HTMLDivElement) {
                                effect(() => {
                                    element.classList.toggle(
                                        withNamespace('u-hide'),
                                        activeTab.read() !== 'sidebet',
                                    );
                                });
                            },
                        },

                        BetAmount({
                            label: 'Side Bet (Perfect Pairs)',
                            tooltip:
                                'The amount of money you want to bet on Perfect Pairs side bet.',
                            value: perfectPairAmount,
                            disabled: bettingLocked,
                        }),

                        BetAmount({
                            label: 'Side Bet (21 + 3)',
                            tooltip: 'The amount of money you want to bet on 21+3 side bet.',
                            value: pokerAmount,
                            disabled: bettingLocked,
                        }),
                    ),

                    div(
                        {
                            className: { 'l-stack': true, 'u-gap--xs': true },
                            ref: handleInsuranceAvailable({ show: false }),
                        },
                        div(
                            { className: { 'l-flex': true } },
                            actionButton('Hit', 'raise'),
                            actionButton('Stand', 'stop'),
                        ),
                        div(
                            { className: { 'l-flex': true } },
                            actionButton('Split', 'cards'),
                            actionButton('Double', 'coins'),
                        ),
                        div({ className: { 'l-flex': true } }, actionButton('Surrender', 'flag')),
                    ),

                    div(
                        {
                            className: { 'l-stack': true, 'u-gap--m': true },
                            ref: handleInsuranceAvailable({ show: true }),
                        },
                        Label({
                            text: 'Would you like insurances?',
                            variant: 'medium',
                        }),
                        Button({
                            cta: true,
                            label: 'Accept Insurance',
                            variant: 'secondary',
                            onClick() {
                                void client.peek({ accepted_insurance: true }).catch(console.error);
                            },
                        }),
                        Button({
                            cta: true,
                            label: 'No Insurance',
                            variant: 'tertiary',
                            onClick() {
                                void client
                                    .peek({ accepted_insurance: false })
                                    .catch(console.error);
                            },
                        }),
                    ),

                    div(
                        {
                            className: { 'l-flex': true },
                            ref: (element: HTMLDivElement) => {
                                effect(() => {
                                    element.classList.toggle(
                                        withNamespace('u-hide'),
                                        bettingLocked(),
                                    );
                                });
                            },
                        },
                        betButton,
                    ),
                ),

                Frame.Section(
                    { className: { 'l-stack__split': true, 'l-stack': true, 'u-gap--s': true } },
                    Options({ client, context }),
                    html('a')(
                        { href: '#', target: '_blank', className: { 'u-text--center': true } },
                        Label(
                            { variant: 'subtle', icon: 'double-check' },
                            html('span')({ className: namespaced('u-hide@m') }, 'This Game is'),
                            'Provably Fair',
                        ),
                    ),
                ),
            );
        },
);
