import { type SignalReader } from '@monkey-tilt/state';
import {
    Button,
    ButtonBar,
    defineComponent,
    mergeClasses,
    withNamespace,
    type ButtonProps,
    type Component,
    type Props,
} from '@monkey-tilt/ui';
import type { BlackjackClient } from '../../../game/blackjack';
import { scopedState } from '../../../util/state';
import type { AppContext } from '../../context';

export interface OptionsProps extends Props<HTMLDivElement> {
    /**
     * The Blackjack game client.
     */
    readonly client: BlackjackClient;

    readonly context: AppContext;
}

export const Options = defineComponent(
    'Options',
    () =>
        ({ client, context, ...props }: OptionsProps): Component<HTMLDivElement> => {
            const { effect, memo, ref } = scopedState<HTMLDivElement>();

            const buttons: (ButtonProps & { signal?: SignalReader<boolean> })[] = [
                {
                    signal: memo(() => !context.isMuted()),
                    onClick: () => context.setMuted((isMuted) => !isMuted),
                    icon: 'sound',
                    title: 'Sound effects',
                },
                // {
                //     signal: music,
                //     onClick: () => client.toggleMute('music'),
                //     icon: 'music',
                //     title: 'Music',
                // },
                {
                    icon: 'notepad',
                    title: 'Notes',
                    href: '#',
                    target: '_blank',
                },
                {
                    icon: 'clockwise',
                    title: 'History',
                    href: '#',
                    target: '_blank',
                },
                {
                    icon: 'lock',
                    title: 'Lock something',
                    href: '#',
                    target: '_blank',
                },
                {
                    icon: 'circle-dollar',
                    title: 'Account balance',
                    href: '#',
                    target: '_blank',
                },
            ];

            return ButtonBar(
                {
                    ...props,
                    ref,
                    className: mergeClasses(props.className, withNamespace('m-blackjack__options')),
                },
                ...buttons.map(({ signal, ...button }) =>
                    Button({
                        ...button,
                        variant: 'secondary',
                        ref:
                            signal &&
                            ((element: HTMLButtonElement) => {
                                effect(() => {
                                    element.classList.toggle(withNamespace('is-active'), signal());
                                });
                            }),
                    }),
                ),
            );
        },
);
