import { AudioController, type SoundSprite } from '@monkey-tilt/client';
import { effect, signal, type SignalReader, type SignalUpdater } from '@monkey-tilt/state';
import type { RefObject, ToastBarControls, ToastControls, ToastProps } from '@monkey-tilt/ui';

import effects from './assets/effects.json';
import effectsMP3 from './assets/effects.mp3';
import effectsOGG from './assets/effects.ogg';
import effectsWEBM from './assets/effects.webm';

export type SoundEffect = keyof typeof effects;

export interface AppContextRefs {
    readonly toastBarControlsRef?: RefObject<ToastBarControls>;
}

const nullRef = { current: null };

export class AppContext {
    #toastBarControlsRef: RefObject<ToastBarControls>;
    #audio: AudioController;
    #soundEffects: SoundSprite;

    #isMuted: SignalReader<boolean>;
    #setMuted: SignalUpdater<boolean>;

    public constructor({ toastBarControlsRef = nullRef }: AppContextRefs) {
        this.#toastBarControlsRef = toastBarControlsRef;

        this.#audio = new AudioController();
        this.#soundEffects = this.#audio.createSoundSprite({
            sources: [
                { src: effectsWEBM, type: 'audio/webm; codecs=opus' },
                { src: effectsOGG, type: 'audio/ogg; codecs=vorbis' },
                { src: effectsMP3, type: 'audio/mpeg' },
            ],
            sprites: effects,
        });

        [this.#isMuted, this.#setMuted] = signal(this.#audio.muted);

        effect(() => {
            this.#audio.muted = this.#isMuted();
        });
    }

    public get isMuted(): SignalReader<boolean> {
        return this.#isMuted;
    }

    public setMuted(muted: boolean | ((isMuted: boolean) => boolean)): void {
        this.#setMuted(muted);
    }

    public showToast(toast: ToastProps): ToastControls | null {
        if (this.#toastBarControlsRef.current) {
            return this.#toastBarControlsRef.current.showToast({
                dismiss: { after: 2000 },
                ...toast,
            });
        }
        return null;
    }

    public playSound(sprite: SoundEffect): Promise<void> {
        return this.#soundEffects.play(sprite);
    }
}
