import { type Signal, type SignalReader } from '@monkey-tilt/state';
import {
    Button,
    defineComponent,
    html,
    Input,
    Label,
    mergeClasses,
    Stepper,
    type Component,
    type InputControls,
    type InputProps,
    type RefObject,
} from '@monkey-tilt/ui';
import { scopedState } from '../../../util/state';

export interface BetAmountProps extends Omit<InputProps, 'step' | 'value' | 'disabled'> {
    /**
     * The signal representing the current bet amount value.
     */
    readonly value: Signal<string>;

    /**
     * The signal representing the disabled state of the input.
     */
    readonly disabled?: SignalReader<boolean>;

    /**
     * The preset amounts to be displayed as buttons below the input.
     */
    readonly presets?: number[];

    /**
     * The step value to be used when incrementing or decrementing the value. Defaults to 5.
     */
    readonly step?: number;

    /**
     * The number of fraction digits to be displayed. Defaults to 2.
     */
    readonly fractionDigits?: number;

    /**
     * The label to be displayed above the input.
     */
    readonly label: string;

    /**
     * The label tooltip.
     */
    readonly tooltip?: string;
}

export const BetAmount = defineComponent(
    'BetAmount',
    () =>
        ({
            value: valueSignal,
            disabled: disabledSignal = () => false,
            presets = [],
            step = 0.1,
            fractionDigits = 2,
            label,
            tooltip = '',
            className,
            suffix = 'USD',
            ...props
        }: BetAmountProps): Component<HTMLDivElement> => {
            const { effect, ref } = scopedState<HTMLDivElement>();

            const inputWrapper: RefObject<HTMLDivElement> = { current: null };
            const input: RefObject<InputControls> = { current: null };
            const zero = (0).toFixed(fractionDigits);
            const id = props.id ?? `bet-amount-${Math.random().toString(36).slice(2)}`;

            const mask = (value: string, prevValue: string): string => {
                if (value === '' || value === '.') {
                    return prevValue == '0' ? prevValue : zero;
                }
                return /^\d*(\.\d*)?$/.test(value) ? value.replace(/^00+|000+$/g, '0') : prevValue;
            };

            const update = (value: string, delta?: { inc: number } | { mul: number }): void => {
                const num = Number.parseFloat(value);
                if (!Number.isFinite(num)) {
                    value = zero;
                } else if (delta) {
                    if ('inc' in delta) {
                        value = Math.max(0, num + delta.inc).toFixed(fractionDigits);
                    } else {
                        value = Math.abs(num * delta.mul).toFixed(fractionDigits);
                    }
                } else {
                    value = num.toFixed(fractionDigits);
                }
                valueSignal.update(value);
            };

            const handleDisabledState = (element: HTMLElement): void => {
                effect(() => {
                    const isDisabled = disabledSignal();
                    if (element && 'disabled' in element) {
                        element.disabled = isDisabled;
                    }
                });
            };

            update(mask(valueSignal.read(), zero));

            effect(() => {
                const value = valueSignal.read();
                if (input.current) {
                    input.current.update(value);
                }
            });

            return html('div')(
                {
                    ref,
                    className: mergeClasses(className, { 'l-stack': true, 'u-gap--s': true }),
                },
                Label({ text: label, tooltip, htmlFor: id }),
                Input(
                    {
                        ref(element: HTMLDivElement) {
                            inputWrapper.current = element;
                        },

                        suffix,
                        id,
                        ...props,

                        value: valueSignal.read(),
                        inputMode: 'decimal',
                        mask,
                        onChange(event) {
                            if (event.target instanceof HTMLInputElement) {
                                valueSignal.update(event.target.value);
                            }
                        },

                        onKeydown(event) {
                            if (!(event.target instanceof HTMLInputElement)) {
                                return;
                            }

                            const { selectionStart, selectionEnd } = event.target;

                            switch (event.key) {
                                case 'ArrowUp':
                                    update(valueSignal.read(), { inc: step });
                                    break;
                                case 'ArrowDown':
                                    update(valueSignal.read(), { inc: -step });
                                    break;
                                default:
                                    return;
                            }

                            event.preventDefault();
                            event.target.setSelectionRange(selectionStart, selectionEnd);
                        },

                        onFocus(event) {
                            if (event.target instanceof HTMLInputElement) {
                                event.target.select();
                                const index = event.target.value.indexOf('.');
                                if (index >= 0) {
                                    event.target.setSelectionRange(0, index);
                                }
                            }
                        },

                        onBlur() {
                            update(valueSignal.read());
                        },

                        action: Stepper({
                            incrementRef: handleDisabledState,
                            decrementRef: handleDisabledState,
                            onIncrement(e) {
                                e.stopPropagation();
                                update(valueSignal.read(), { inc: step });
                            },
                            onDecrement(e) {
                                e.stopPropagation();
                                update(valueSignal.read(), { inc: -step });
                            },
                        }),

                        inputRef: handleDisabledState,
                        controls: (controls) => {
                            input.current = controls;
                        },
                    },
                    ...[
                        { label: '1/2', mul: 0.5 },
                        { label: '2x', mul: 2 },
                    ].map(({ label, mul }) =>
                        Button({
                            variant: 'tertiary',
                            ref: handleDisabledState,
                            onClick: (e) => {
                                e.stopPropagation();
                                update(valueSignal.read(), { mul });
                            },
                            label,
                        }),
                    ),
                ),
                presets.length > 0 &&
                    html('div')(
                        { className: { 'l-cluster': true } },
                        ...presets.map((value) => {
                            const presetValue = value.toFixed(fractionDigits);
                            return Button({
                                variant: 'secondary',
                                ref: handleDisabledState,
                                onClick: (e) => {
                                    e.stopPropagation();
                                    update(presetValue);
                                },
                                label: presetValue,
                            });
                        }),
                    ),
            );
        },
);
