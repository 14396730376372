import { GameError, timer, wait } from '@monkey-tilt/client';
import {
    defineComponent,
    Frame,
    html,
    mergeClasses,
    type Component,
    type Props,
    type RefObject,
    type ToastBarControls,
    type ToastControls,
} from '@monkey-tilt/ui';
import type { BlackjackClient } from '../../../game/blackjack';
import { scopedState } from '../../../util/state';
import { AppContext } from '../../context';
import { Options } from '../options/options';
import { Sidebar } from '../sidebar/sidebar';
import { Table } from '../table/table';

export interface BlackjackProps extends Props<HTMLDivElement> {
    /**
     * The Blackjack game client.
     */
    readonly client: BlackjackClient;
}

export const Blackjack = defineComponent(
    'Blackjack',
    () =>
        ({ client, ...props }: BlackjackProps): Component<HTMLDivElement> => {
            const { memo, effect, ref } = scopedState<HTMLDivElement>();

            const replayTimer = timer(2000);
            const openRound = () =>
                void client
                    .open((error) =>
                        error instanceof GameError ? error.code !== 'ROUND_ALREADY_ACTIVE' : true,
                    )
                    .catch(console.error);

            const statusTimer = timer(1500);

            const toastBarControlsRef: RefObject<ToastBarControls> = { current: null };

            const context = new AppContext({ toastBarControlsRef });

            const clientError = memo(() => client.state().error);
            let errorToast: ToastControls | null = null;

            effect(() => {
                const error = clientError();

                if (errorToast) {
                    errorToast.dismiss();
                    errorToast = null;
                }

                if (error) {
                    errorToast = context.showToast({
                        variant: 'error',
                        dismiss: { after: 30 * 60 * 60 * 1000 },
                        title: 'Error',
                        content:
                            error.code === 'AUTH_FAILED'
                                ? 'Please log in to play.'
                                : error.code === 'ROUND_ALREADY_ACTIVE'
                                  ? 'You already have a game in progress.'
                                  : error.message,
                    });
                }
            });

            const isOpenAvailable = memo(() => {
                const { readyState, next_actions } = client.state();
                return readyState === 'ready' && next_actions.includes('Open');
            });

            let isReplay = false;

            effect(() => {
                if (isOpenAvailable()) {
                    if (isReplay) {
                        replayTimer(openRound);
                    } else {
                        openRound();
                        isReplay = true;
                    }
                }
            });

            const roundStatus = memo(() => {
                const {
                    round_closed = false,
                    total_payout = '0',
                    currency,
                    player_hands,
                } = client.state();
                return {
                    roundClosed: round_closed,
                    totalPayout: Number.parseFloat(total_payout),
                    currency,
                    status: player_hands.some((hand) => hand.status === 'win')
                        ? 'win'
                        : player_hands.some((hand) => hand.status === 'push')
                          ? 'push'
                          : player_hands.some((hand) => hand.status === 'bust')
                            ? 'bust'
                            : undefined,
                };
            });

            effect(() => {
                const { roundClosed, totalPayout, currency, status } = roundStatus();
                if (roundClosed) {
                    wait(4000, () => client.reset());
                    statusTimer(() => {
                        context.showToast({
                            title:
                                status &&
                                (status === 'win'
                                    ? 'Win!'
                                    : status === 'bust'
                                      ? 'Bust'
                                      : undefined),
                            variant:
                                status === 'win'
                                    ? 'success'
                                    : status == 'bust'
                                      ? 'error'
                                      : status === 'push'
                                        ? 'warning'
                                        : 'default',
                            content:
                                status === 'win'
                                    ? `Total payout: ${totalPayout.toFixed(2)} ${currency}`
                                    : status === 'push'
                                      ? 'Push'
                                      : 'Better luck next time!',
                        });
                    });
                }
            });

            const insurance = memo(() => {
                const { insurance = { payout: undefined, status: undefined }, currency } =
                    client.state();
                return {
                    currency,
                    payout: insurance.payout ? Number.parseFloat(insurance.payout) : 0,
                    won: insurance.status && insurance.status === 'insurance_won',
                };
            });

            effect(() => {
                const { payout, won, currency } = insurance();
                if (won !== undefined) {
                    statusTimer(() => {
                        context.showToast({
                            title: won ? 'Win!' : 'Loss',
                            variant: won ? 'success' : 'error',
                            content: won
                                ? [
                                      'Insurance bet won!',
                                      html('br')(),
                                      `Payout: ${payout.toFixed(2)} ${currency}`,
                                  ]
                                : 'Insurance bet lost.',
                        });
                    });
                }
            });

            const poker = memo(() => {
                const { poker = { payout: undefined, status: undefined }, currency } =
                    client.state();
                return {
                    currency,
                    payout: poker.payout ? Number.parseFloat(poker.payout) : 0,
                    status:
                        poker.status &&
                        (poker.status === 'sidebet_lost' ? (false as const) : poker.status),
                };
            });

            effect(() => {
                const { payout, status, currency } = poker();
                if (status) {
                    statusTimer(() => {
                        context.showToast({
                            title: 'Win!',
                            variant: 'success',
                            content: [
                                (status === 'suited_trips'
                                    ? 'Suited Trips'
                                    : status === 'straight_flush'
                                      ? 'Straight Flush'
                                      : status === 'three_of_a_kind'
                                        ? 'Three of a Kind'
                                        : status === 'straight'
                                          ? 'Straight'
                                          : 'Flush') + '!',
                                html('br')(),
                                `Payout: ${payout.toFixed(2)} ${currency}`,
                            ],
                        });
                    });
                }
            });

            const perfect_pair = memo(() => {
                const { perfect_pairs = { payout: undefined, status: undefined }, currency } =
                    client.state();
                return {
                    currency,
                    payout: perfect_pairs.payout ? Number.parseFloat(perfect_pairs.payout) : 0,
                    status:
                        perfect_pairs.status &&
                        (perfect_pairs.status === 'sidebet_lost'
                            ? (false as const)
                            : perfect_pairs.status),
                };
            });

            effect(() => {
                const { payout, status, currency } = perfect_pair();
                if (status) {
                    statusTimer(() => {
                        context.showToast({
                            title: 'Win!',
                            variant: 'success',
                            content: [
                                (status === 'perfect_pair'
                                    ? 'Perfect Pair'
                                    : status === 'coloured_pair'
                                      ? 'Coloured Pair'
                                      : status === 'mixed_pair'
                                        ? 'Mixed Pair'
                                        : 'Pair') + '!',
                                html('br')(),
                                `Payout: ${payout.toFixed(2)} ${currency}`,
                            ],
                        });
                    });
                }
            });

            return html('div')(
                {
                    ...props,
                    ref,
                    className: mergeClasses(props.className, { 'm-blackjack': true }),
                },
                Sidebar({ client, context }),
                Table({ context, client, toastBarControlsRef }),
                Frame(
                    { variant: 'small', className: { 'm-blackjack__options-bar': true } },
                    Options({ client, context }),
                ),
            );
        },
);
