/**
 * Creates a subset of an object containing only the specified keys.
 *
 * @param obj - The object to pick from.
 * @param keys - The keys to pick.
 * @returns The object containing only the specified keys.
 */
export function pick<T, K extends keyof T>(obj: T, ...keys: K[]): Pick<T, K> {
    const result = {} as Pick<T, K>;
    for (const key of keys) {
        result[key] = obj[key];
    }
    return result;
}
