/**
 * Strips readonly modifier from all properties of T.
 */
export type Writable<T> = {
    -readonly [P in keyof T]: T[P];
};

/**
 * Casts a value to a writable type.
 *
 * @param value - The value to cast.
 * @returns The writable value.
 */
export function writable<T>(value: T): Writable<T> {
    return value as Writable<T>;
}
