import { wait } from '@monkey-tilt/client';
import {
    defineComponent,
    forwardRef,
    html,
    mergeClasses,
    namespaced,
    withNamespace,
    type Component,
    type Props,
} from '@monkey-tilt/ui';
import type { Card as CardData } from '../../../game/state';

export interface CardProps extends Props<HTMLDivElement> {
    readonly card?: CardData | undefined;
    readonly revealAfter?: number;
    readonly onReveal?: () => void;
}

const suitNames = ['Hearts', 'Diamonds', 'Clubs', 'Spades'];

function cardSuitName(card?: CardData): string {
    return card ? suitNames[card.suit]! : '';
}

const valueNames = ['Ace', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'Jack', 'Queen', 'King'];

function cardValueName(card?: CardData): string {
    return card ? valueNames[card.index]! : '';
}

const revealAnimationDuration = 600;

export const Card = defineComponent(
    'Card',
    () =>
        ({ card, revealAfter = -1, onReveal, ...props }: CardProps): Component<HTMLDivElement> => {
            const div = html('div');
            const suit = cardSuitName(card);
            const value = cardValueName(card);

            return div(
                {
                    role: 'img',
                    ariaLabel: card ? `Card: ${value} of ${suit}` : 'Face-down card',
                    'data-card-id': card?.id,
                    ...props,
                    ref: forwardRef(props.ref, (element: HTMLDivElement) => {
                        if (revealAfter > 0 && card) {
                            wait(revealAfter, () => {
                                element.classList.toggle(withNamespace('m-card--face-down'));
                            }).wait(revealAnimationDuration / 2)(() => {
                                onReveal?.();
                            });
                        }
                    }),
                    className: mergeClasses(props.className, {
                        'm-card': true,
                        'm-card--face-down': revealAfter >= 0,
                        [`m-card--${suit.toLowerCase()}`]: !!card,
                        [`m-card--${value.toLowerCase()}`]: !!card,
                    }),
                },
                div(
                    { className: namespaced('m-card__content') },
                    div(
                        { className: namespaced('m-card__face') },
                        div({ className: namespaced('m-card__value') }, div()),
                        div(
                            { className: namespaced('m-card__pips') },
                            ...[4, 3, 4].map((numPips) =>
                                div(
                                    { className: namespaced('m-card__col') },
                                    ...new Array<Component<HTMLElement>>(numPips).fill(div()),
                                ),
                            ),
                        ),
                        div({ className: namespaced('m-card__value') }, div()),
                    ),
                    div({ className: namespaced('m-card__back') }, div()),
                ),
            );
        },
);
